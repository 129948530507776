const delay = 0.2

export const setSwipeState = (width, stateType, handleType) => {
  try {
    if (handleType === "OPEN") {
      if (stateType === "EXIT") {
        return {
          length: delay,
          state: { x: -width, opacity: 0 },
        }
      }
      if (stateType === "ENTRY") {
        return {
          delay: delay,
          state: { x: width },
        }
      }
    }
    if (handleType === "CLOSE") {
      if (stateType === "EXIT") {
        return {
          length: delay,
          state: { x: width, opacity: 0 },
        }
      }

      if (stateType === "ENTRY") {
        return {
          delay: delay,
          state: { x: -width },
        }
      }
    }
  } catch (err) {
    throw new Error(err)
  }
}
