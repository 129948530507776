import styled, { css } from 'styled-components'

export const media = {
  tablet: styles => css`
    @media only screen and (min-width:600px){
      ${styles}
    }
  `,
  pc: styles => css`
    @media only screen and (min-width:1030px){
      ${styles}
    }
  `
}
